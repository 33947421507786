@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: "Poppins", sans-serif; */
}

/* Hero Section Start */


.hero-section{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8rem;
    background: #686D76;
    min-height: 100vh;
    padding: 5rem 9% 5rem;
}

.hero-section .hero-content h1{
    font-size: 3.75rem;
    font-weight: 700;
    line-height: 1.3;
    color: #fff;
}

.hero-sction span{
    color: blue;
}

.hero-content h3{
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: 700;
    color: #fff;
}



.hero-img img{
    /* position: relative;
    width: 30vw; */
    width: 100%;
    max-width: 450px;
    height: auto;

}

.hero-content p{
    font-size: 1.125rem;
    font-weight: 500;
    color: #fff;
}

.hero-section .btn{
    text-transform: uppercase;
    border-radius: 1rem;
    text-decoration: none;
    font-size: 1rem;
    color: white;
    font-weight: 700;
    background: #121317;
    padding: 1em 2.1em 1.1em;
    transition: 0.3s;
    cursor: pointer;
}

.hero-section .btn:hover{
    background: #373A40;
}

.hero-social-icon span {
    color: #fff;
    display: block;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    /* margin-top: 20px; */
}

.hero-social-icon a {
    color: #fff;
    font-size: 16px;
    margin-right: 15px;
}

.hero-social-icon i {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
}

.facebook-bg{
    background: #3B5998;
}

.instagram-bg{
    background: #e1306c;
}

.youtube-bg{
    background: #ff0000;
}

.tiktok-bg{
    background: #000000;
}

.linkedin-bg{
    background: #0077b5;
}   

@media (max-width:1000px){
    .hero-section{
        gap: 4rem;
    }
}
@media (max-width:995px) {
    .hero-section{
        flex-direction: column;
        /* margin: 5rem 4rem; */
    }
    .hero-section .hero-content h3{
        font-size: 1.5625rem;
    }
    .hero-content h1{
        font-size: 3.125rem;
    }
    /* .hero-img img{
        width: 43.75vw;
        margin-top:0rem;
    } */
}

@media (max-width:768px){

}

/* Hero Section End */

/* about section start */


.about-section{
    width: 100%;
    min-height: 94vh;
    background-color: #FEFDED;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-section .about-content img{
    height: auto;
    width: 600px;
    max-width: 100%;
}

.about-text{
    width: 550px;
    max-width: 100%;
    padding: 0 10px;
}

.about-content{
    width: 1280px;
    max-width: 95%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.about-text h1{
    color: #373A40;
    font-size: 85px;
    margin-bottom: 20px;
    text-transform: capitalize;
}

.about-text h5{
    color: black;
    font-size: 25px;
    margin-bottom: 25px;
    text-transform: capitalize;
    letter-spacing: 2px;
}

.about-text p{
    color: black;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 1px;
    margin-bottom: 45px;
    text-align: justify;
}


.about-section .about-btn{
    padding: .7rem 3rem;
    font-size: 1.1rem;
    color: #fff;
    background: red;
    border-radius: 5rem;    
    /* margin-top: -10rem; */
    cursor: pointer;
    box-shadow: 0 .1rem .3rem rgba(0, 0, 0, .3);
    position: relative;
    overflow: hidden;
    z-index: 0;
    border: 0;
}

.about-btn::before{
    content: '';
    position: absolute;
    top: -1rem; left: -100%;
    background: #fff9;
    height: 150%;
    width: 20%;
    transform: rotate(25deg);
    z-index: -1;
}

.about-btn:hover::before{
    transition: .3s linear;
    left: 120%;
}


/* .about-section .about-text a{
    background-color: #498e8a;
    color: white;
    border: 2px solid transparent;
    text-decoration: none;
    padding: 10px 30px;
    border-radius: 30px;
    font-size: 17px;
    font-weight: bold;
}

.about-section .about-text a:hover{
    transform: scale(1.2);
    background-color: transparent;
    border: 2px solid #498e8a;
    color: #498e8a;
    transition: .4s;
    cursor: pointer;
} */

@media screen and (max-width: 1180px) {
    .about-section{
        width: 100%;
        height: auto;
        padding: 70px 0px;
    }
}

@media screen and (max-width: 650px) {
    .about-section .about-content img{
        margin-bottom: 35px;
    }
    .about-text h1{
        font-size: 60px;
        margin-bottom: 25px;
    }
}


/* about section end */




/* category section start */

.main-category-section{
    background: #686D76;
}

.main-category-section h1{
    color: white;
    text-align: center;
}

.main-category-section h1{
    font-size: 2.2rem;
    font-weight: 500;
    text-align: center;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.main-category-section h1:after{
    content: '';
    position: absolute;
    width: 100px;
    height: 4px;
    background-color: #003d7e;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
}


.category-section{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
    width: 100%;
}

.category-container{
    position: relative;
    width: 1000px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

}

.category-container .category-card{
    position: relative;
    height: 250px;
    background: #fff;
    display: flex;
    width: 100%;
    margin: 30px 0;
    border-radius: 1rem;
}

.category-container .category-card .category-imgBx{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #121317;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: 0.5s ease-in-out;
    border-radius: 1rem;
}

.category-container .category-card:hover .category-imgBx{
    width: 150px;
    height: 150px;
    left: -75px;
    top: calc(50% - 75px);
    transition: 0.5s ease-in-out;
    /* background: #FF9800; */
}






/* code chatgpt start*/

.category-container .category-card:hover .category-imgBx::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* back ground: rgba(0, 0, 0, 0.5); */
    z-index: 1;
}

.category-container .category-card.category-imgBx::after {
    content: none; /* Hapus teks yang ditampilkan di hover */
}

/* code chatgpt end*/









.category-container .category-card .category-imgBx::before{
    content: attr(data-text);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 2.8em;
    color: #FEFDED;
    font-weight: 700;
}
/* 
.category-container .category-card .category-imgBx:hover::before{
    opacity: 0;
    color: red;
} */

.category-container .category-card .category-imgBx img{
    max-width: 100px;
    transition: 0.5s ease-in-out;   
}

.category-container .category-card:hover .category-imgBx img{
    max-width: 75px;
}

.category-container .category-card .category-content{
    position: absolute;
    right: 0;
    width: calc(100% - 75px);
    height: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.category-container .category-card .category-content h3{
    margin-bottom: 5px;
    font-size: 24px;
}

.category-container .category-card .category-content a{
    display: inline-block;
    margin-top: 10px;
    padding: 5px 10px;
    background: #333;
    text-decoration: none;
    color: #fff;
}
@media (max-width: 992px){
    .category-container{
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
    .category-container .category-card{
        width: 400px;
    }


    
    /* code chatgpt start*/

    .category-container .category-card .category-imgBx::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* back ground: rgba(0, 0, 0, 0.5); */
        z-index: 1;
    }
    
    .category-container .category-card.category-imgBx::after {
        content: none; /* Hapus teks yang ditampilkan di hover */
    }

    /* code chatgpt start*/


}

@media (max-width: 768px){
    .category-container .category-card{
        max-width: 300px;
        flex-direction: column;
        height: auto;
    }    
    .category-container .category-card .category-imgBx{
        position: relative;
    }
    .category-container .category-card .category-imgBx,
    .category-container .category-card:hover .category-imgBx{
        width: 100%;
        height: 200px;
        left: 0;
    }
    .category-container .category-card .category-imgBx img,
    .category-container .category-card:hover .category-imgBx img{
        max-width: 100px;
    }
    .category-container .category-card .category-content{
        position: relative;
        width: 100%;
    }
}



/* category section end */





/* after event start */

.after-event-section{
    min-height: 100vh;
    background: #fff;
    padding: 5rem 9% 5rem;
}

.after-event-row{
    /* display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem; */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
}

/* @media (max-width: 768px){
    .after-event-row{
        flex-direction: column;
    }
} */

.after-event-section .after-event-heading h1{
    text-align: center;
}

.after-event-section .after-event-heading h1{
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.after-event-section .after-event-heading h1:after{
    content: '';
    position: absolute;
    width: 100px;
    height: 4px;
    background-color: #121317;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.after-event-section p{
    text-align: justify;
    font-size: 1rem;
    align-items: center;
}

/* after event end */



/* contact start */

.contact-section{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #686D76;
}

.contact-container{
    position: relative;
    min-width: 1100px;
    min-height: 550px;
    display: flex;
    z-index: 1000;
}

.contact-container .contactInfo{
    position: absolute;
    top: 40px;
    width: 350px;
    height: calc(100% - 80px);
    background: #121317;
    z-index: 1;
    padding: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 20px 25px rgba(0, 0, 0, 0.15);
    border-radius: 2rem;
}

.contact-container .contactInfo h2{
    color: #fff;
    font-size: 24px;
    font-weight: 500;
}

.contact-container .contactInfo ul.info{
    position: relative;
    margin: 20px 0;
}

.contact-container .contactInfo ul.info li{
    position: relative;
    list-style: none;
    display: flex;
    margin: 20px 0;
    cursor: pointer;
    align-items: flex-start;
}

.contact-container .contactInfo ul.info li span:nth-child(1){
    width: 30px;
    min-width: 30px;
}

.contact-container .contactInfo ul.info li span:nth-child(1) img{
    max-width: 100%;
    filter: invert(1);
}

.contact-container .contactInfo ul.info li span:nth-child(2){
    color: #fff;
    margin-left: 10px;
    font-weight: 300;
}

.contact-container .contactInfo ul.sci{
    position: relative;
    display: flex;
}

.contact-container .contactInfo ul.sci li{
    list-style: none;
    margin-right: 15px;
}

.contact-container .contactInfo ul.sci li a{
    text-decoration: none;
}

.contact-container .contactInfo ul.sci li a img{
    filter: invert(1);
}

.contact-container .contactForm{
    position: absolute;
    padding: 70px 50px;
    padding-left: 250px;
    margin-left: 150px;
    width: calc(100% - 150px);
    height: 100%;
    background: #fff;
    box-shadow: 0 50px 50px rgba(0, 0, 0, 0.25);
    border-radius: 2rem;
}

.contact-container .contactForm h2{
    color: #373A40;
    font-size: 24px;
    font-weight: 500;
}

.contact-container .contactForm .formBox{
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 30px;
}

.contact-container .contactForm .formBox .inputBox{
    position: relative;
    margin-bottom: 35px;
}

.contact-container .contactForm .formBox .inputBox.w50{
    width: 100%;
}

.contact-container .contactForm .formBox .inputBox.w100{
    width: 100%;
}

.contact-container .contactForm .formBox .inputBox input,
.contact-container .contactForm .formBox .inputBox textarea{
    width: 100%;
    resize: none;
    padding: 5px 0;
    font-size: 18px;
    font-weight: 300;
    color: #333;
    border: none;
    outline: none;
    border-bottom: 1px solid #777;
}

.contact-container .contactForm .formBox .inputBox textarea{
    height: 120px;
}

.contact-container .contactForm .formBox .inputBox span{
    position: absolute;
    left: 0;
    padding: 5px 0;
    pointer-events: none;
    font-size: 18px;
    font-weight: 300;
    transition: 0.3s;
}

.contact-container .contactForm .formBox .inputBox input:focus ~ span,
.contact-container .contactForm .formBox .inputBox input:valid ~ span,
.contact-container .contactForm .formBox .inputBox textarea:focus ~ span,
.contact-container .contactForm .formBox .inputBox textarea:valid ~ span{
    transform: translateY(-20px);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #373A40;
    font-weight: 500;
}

.contact-container .contactForm .formBox .inputBox input[type="submit"]{
    position: relative;
    cursor: pointer;
    background: #121317;
    color: #fff;
    border: none;
    max-width: 150px;
    padding: 12px;
    border-radius: 1rem;
}

.contact-container .contactForm .formBox .inputBox input[type="submit"]:hover{
    background: #373A40;
}

@media (max-width : 1200px) {
    .contact-container{
        width: 90%;
        min-width: auto;
        margin: 20px;
        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
    }
    .contact-container .contactInfo{
        top: 0;
        height: 500px;
        position: relative;
        box-shadow: none;
    }
    .contact-container .contactForm{
        position: relative;
        width: calc(100% - 350px);
        padding-left: 0;
        margin-left: 0;
        padding: 40px;
        height: 550px;
        box-shadow: none;
    }
}

@media (max-width : 991px) {
    .contact-container{
        display: flex;
        flex-direction: column-reverse;
    }
    .contact-container .contactForm{
        width: 100%;
        height: auto;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .contact-container .contactInfo{
        width: 100%;
        height: auto;
        flex-direction: row;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .contact-container .contactInfo ul.sci{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width : 600px){
    .contact-container .contactForm{
        padding: 25px;
    }
    .contact-container .contactInfo{
        padding: 25px;
        flex-direction: column;
        align-items: flex-start;
    }
    .contact-container .contactInfo ul.sci{
        margin-top: 40px;
    }
}

@media (max-width : 400px){
    .contact-container .contactInfo{
        font-size: 12px;
    }
}

/* contact end */

/* social media start */

.social-media-section{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    background: #f5f5f5;
    flex-direction: column;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.social-media-section h2{
    text-align: center;
    color: #121317;
    font-size: 42px;
    margin-bottom: 20px;
}

.social-media-section ul{
    position: relative;
    display: flex;
}

.social-media-section ul li{
    position: relative;
    list-style: none;
}

.social-media-section ul li a{
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    margin: 0 10px;
    text-decoration: none;
    font-size: 30px;
    border-radius: 50%;
    color: #E2E2E2;
    box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.2),
                2px 2px 5px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
}

.social-media-section ul li a:hover{
    color: #999;
    transform: translateY(-20px);
    box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.2),
                2px 24px 10px rgba(0, 0, 0, 0.1);
}

.social-media-section ul li a .fab{
    color: #fff;
    position: relative;
    z-index: 1;
}


.social-media-section ul li a:before{
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.1);
}


.social-media-section ul li:nth-child(1) a:before{
    background: #1877f2;
}

.social-media-section ul li:nth-child(2) a:before{
    background: #e1306c;
}

.social-media-section ul li:nth-child(3) a:before{
    background: #ff0000;
}

.social-media-section ul li:nth-child(4) a:before{
    background: #000000;
}

.social-media-section ul li:nth-child(5) a:before{
    background: #0077b5;
}

@media (max-width:600px){
    .social-media-section ul li a{
        width: 50px;
        height: 50px;
        font-size: 15px;
    }
    
}

@media (max-width:400px){
    .social-media-section ul li a{
        width: 40px;
        height: 40px;
        font-size: 15px;
        margin: 0 4px;
    }
    .social-media-section ul li a:before{
        top: 6px;
        left: 6px;
        right: 6px;
        bottom: 6px;
    }
}

/* social media end */

/* newsletter start */

.newsletter-section .newsletter-main_wrapper{
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.newsletter-section .newsletter-main_wrapper .newsletter-subscribe_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #686D76;
    /* background-image: linear-gradient(to left, rgba(0,0,0,0.5), rgba(0,0,0,0.5), url('https://cdn.pixabay.com/photo/2017/02/08/17/24/fantasy-2049567_960_720.jpg')); */
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.newsletter-section .newsletter-main_wrapper .newsletter-subscribe_wrapper h2{
    color: #fff;
    font-size: 42px;
    margin-bottom: 20px;
}

.newsletter-section .newsletter-main_wrapper .newsletter-subscribe_wrapper p{
    color: #fff;
    font-size: 16px;
    margin-bottom: 40px;
    text-align: center;
}

.newsletter-section .newsletter-main_wrapper .newsletter-subscribe_wrapper form{
    width: 90%;
    max-width: 550px;
    position: relative;
}

.newsletter-section .newsletter-main_wrapper .newsletter-subscribe_wrapper input{
    width: 100%;
    max-width: 550px;
    border: none;
    outline: none;
    padding: 16px 24px;
    border-radius: 25px;
    font-size: 16px;
}

.newsletter-section .newsletter-main_wrapper .newsletter-subscribe_wrapper button{
    position: absolute;
    top: 50%;
    right: 6px;
    background-color: #121317;
    padding: 10px 32px;
    border: none;
    color: #fff;
    border-radius: 25px;
    font-size: 16px;
    transform: translateY(-50%);
    font-weight: 600;
    transition: .3s;
}

.newsletter-section .newsletter-main_wrapper .newsletter-subscribe_wrapper button:hover{
    background: #373A40;
}



/* newsletter end */

/* logo start */

.brand-logo-section{
    width: 100%;
    display: grid;
    place-items: center;
    background: #f2f2f2;
}

.brand-logo-section .brand-logo-container h1{
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.brand-logo-section .brand-logo-container h1:after{
    content: '';
    position: absolute;
    width: 100px;
    height: 4px;
    background-color: #121317;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.brand-logo-section .brand-logo-container{
    width: 90%;
    height: auto;
    text-align: center;
}

.brand-logo-section .brand-logo{
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
}

.brand-logo-section .brand-logo ul{
    padding: 0;
    margin: 0;
}

.brand-logo-section .brand-logo ul li{
    list-style: none;
    display: inline-block;
    height: 100px;
    cursor: pointer;
    transition: .5s;
}

.brand-logo-section .brand-logo ul li img{
    width: 100%;
    height: 100%;
}

/* logo end */
